import * as tslib_1 from "tslib";
import { Router } from '@angular/router';
import { ExtendedRequestParams } from '@imunify360-api/misc';
import { XHR } from 'app/utils/xhr';
import { AbstractService } from 'app/services/abstract-service';
import { License, LicenseAPI } from '@imunify360-api/license';
import { AuthService } from './auth';
import { BehaviorSubject } from 'rxjs';
import { testMode } from 'app/services/misc';
var day = 24 * 60 * 60 * 1000;
var getTestData = function () { return ({
    status: !window['i360LicenseFail'],
    license_type: 'imunify360',
    expiration: (Date.now() + 2 * day) / 1000,
    user_limit: 10,
    user_count: 11,
    redirect_url: 'https://cln.cloudlinux.com',
    message: 'test message from CLN',
    upgrade_url: 'https://ui-test-server:55555/test/test.html',
    ip_license: false,
}); };
var ɵ0 = getTestData;
var LicenseService = /** @class */ (function (_super) {
    tslib_1.__extends(LicenseService, _super);
    function LicenseService(request, router, authService) {
        var _this = _super.call(this, request) || this;
        _this.request = request;
        _this.router = router;
        _this.authService = authService;
        _this.licenseChange = new BehaviorSubject(new License({ status: true }));
        _this.register = _this.serviceRequest(LicenseAPI.register);
        _this.licenseChange.subscribe(function (license) {
            _this.license = license;
        });
        return _this;
    }
    LicenseService.prototype.registerViaPanel = function () {
        return this.xhr.post(new ExtendedRequestParams('applyLicenseFromPanel'));
    };
    LicenseService.prototype.getLicense = function (data) {
        return new License(testMode(this.constructor.name) ? getTestData() : data);
    };
    LicenseService.prototype.checkLicense = function (data) {
        this.licenseChange.next(this.getLicense(data));
        if (this.authService.isClient) {
            return true;
        }
        if (this.license.isFailed() && this.router.url !== '/360/admin/license') {
            this.router.navigate(['/', PACKAGE, 'admin', 'license']);
            return false;
        }
        else if (!this.license.isFailed() && this.router.url === '/360/admin/license') {
            this.router.navigate(['/']);
        }
        else if (!this.license.upgradeAvailable && this.router.url === '/AV/upgrade') {
            this.router.navigate(['/']);
        }
        return true;
    };
    LicenseService.prototype.canActivate = function (route, state) {
        if (this.license.status) {
            this.router.navigate(['/']);
            return false;
        }
        return true;
    };
    LicenseService.prototype.getDefaultMockList = function () {
        return [];
    };
    return LicenseService;
}(AbstractService));
export { LicenseService };
export { ɵ0 };

/*

This program is free software: you can redistribute it and/or modify it under 
the terms of the GNU General Public License as published by 
the Free Software Foundation, either version 3 of the License, 
or (at your option) any later version.


This program is distributed in the hope that it will be useful, 
but WITHOUT ANY WARRANTY; without even the implied warranty of 
MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  
See the GNU General Public License for more details.


You should have received a copy of the GNU General Public License
 along with this program.  If not, see <https://www.gnu.org/licenses/>.

Copyright © 2019 Cloud Linux Software Inc.

This software is also available under ImunifyAV commercial license,
see <https://www.imunify360.com/legal/eula>
*/
import { Injectable } from '@angular/core';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Observable } from 'rxjs';
import { AuthService } from 'app/services/auth';
import { postOnly } from 'app/interceptors/utils';

declare const clientAction: string;
declare const adminAction: string;

@Injectable()
export class UrlInterceptor implements HttpInterceptor {
    constructor(
        private authService: AuthService,
    ) {}

    action = () => this.authService.isAdmin ? adminAction : clientAction;

    @postOnly
    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        // this code should be present in xhr.
        const newUrl = req.url.startsWith('http') ? req.url : this.action() + req.url;
        return next.handle(req.clone({url: newUrl}));
    }
}

/*

This program is free software: you can redistribute it and/or modify it under 
the terms of the GNU General Public License as published by 
the Free Software Foundation, either version 3 of the License, 
or (at your option) any later version.


This program is distributed in the hope that it will be useful, 
but WITHOUT ANY WARRANTY; without even the implied warranty of 
MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  
See the GNU General Public License for more details.


You should have received a copy of the GNU General Public License
 along with this program.  If not, see <https://www.gnu.org/licenses/>.

Copyright © 2019 Cloud Linux Software Inc.

This software is also available under ImunifyAV commercial license,
see <https://www.imunify360.com/legal/eula>
*/
import { WindowInterface } from '@imunify360-api/misc';

declare var window: WindowInterface;

export const testMode = (serviceName: string): boolean => {
    if (!TEST) {
        return false;
    }

    const localStorageMode = JSON.parse(localStorage.getItem('I360_TEST_MODE') || 'false');
    const testMode = localStorageMode || window.i360Test.testMode;

    if (!testMode) {
        return false;
    }

    // enable for specific services
    if (testMode[serviceName]) {
        return true;
    }

    // some services can be in testMode only explicit
    if (['EulaService', 'LicenseService'].includes(serviceName)) {
        return false;
    }

    // global
    return testMode === true;
};

/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "@angular/router";
import * as i2 from "./root.component";
import * as i3 from "../../services/auth";
var styles_RootComponent = [];
var RenderType_RootComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_RootComponent, data: {} });
export { RenderType_RootComponent as RenderType_RootComponent };
export function View_RootComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 16777216, null, null, 1, "router-outlet", [], null, null, null, null, null)), i0.ɵdid(1, 212992, null, 0, i1.RouterOutlet, [i1.ChildrenOutletContexts, i0.ViewContainerRef, i0.ComponentFactoryResolver, [8, null], i0.ChangeDetectorRef], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
export function View_RootComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app", [], null, null, null, View_RootComponent_0, RenderType_RootComponent)), i0.ɵdid(1, 49152, null, 0, i2.RootComponent, [i3.AuthService], null, null)], null, null); }
var RootComponentNgFactory = i0.ɵccf("app", i2.RootComponent, View_RootComponent_Host_0, {}, {}, []);
export { RootComponentNgFactory as RootComponentNgFactory };

import * as tslib_1 from "tslib";
import { from } from 'rxjs';
import { TranslateLoader } from '@ngx-translate/core';
var TranslateHttpLoader = /** @class */ (function (_super) {
    tslib_1.__extends(TranslateHttpLoader, _super);
    function TranslateHttpLoader() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    TranslateHttpLoader.prototype.getTranslation = function (lang) {
        return from(import("i18n/" + lang + ".i18n.txt"));
    };
    return TranslateHttpLoader;
}(TranslateLoader));
export { TranslateHttpLoader };

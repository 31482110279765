/*

This program is free software: you can redistribute it and/or modify it under 
the terms of the GNU General Public License as published by 
the Free Software Foundation, either version 3 of the License, 
or (at your option) any later version.


This program is distributed in the hope that it will be useful, 
but WITHOUT ANY WARRANTY; without even the implied warranty of 
MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  
See the GNU General Public License for more details.


You should have received a copy of the GNU General Public License
 along with this program.  If not, see <https://www.gnu.org/licenses/>.

Copyright © 2019 Cloud Linux Software Inc.

This software is also available under ImunifyAV commercial license,
see <https://www.imunify360.com/legal/eula>
*/
import { Inject, Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { ExtendedRequestParams } from '@imunify360-api/misc';
import { XHR } from 'app/utils/xhr';
import { AbstractService } from 'app/services/abstract-service';
import { License, LicenseAPI } from '@imunify360-api/license';
import { AuthService } from './auth';
import { BehaviorSubject } from 'rxjs';
import { testMode } from 'app/services/misc';


const day = 24 * 60 * 60 * 1000;
const getTestData = () => ({
    status: !window['i360LicenseFail'],
    license_type: 'imunify360',
    expiration: (Date.now() + 2 * day) / 1000,
    user_limit: 10,
    user_count: 11,
    redirect_url: 'https://cln.cloudlinux.com',

    message: 'test message from CLN',
    upgrade_url: 'https://ui-test-server:55555/test/test.html',
    ip_license: false,
});

@Injectable()
export class LicenseService extends AbstractService implements CanActivate {
    license: License;
    licenseChange = new BehaviorSubject(new License({status: true}));
    register = this.serviceRequest(LicenseAPI.register);

    constructor(
        @Inject(XHR) private request: XHR,
        private router: Router,
        private authService: AuthService,
    ) {
        super(request);
        this.licenseChange.subscribe((license) => {
            this.license = license;
        });
    }

    registerViaPanel() {
        return this.xhr.post(new ExtendedRequestParams('applyLicenseFromPanel'));
    }

    getLicense(data: {}): License {
        return new License(testMode(this.constructor.name) ? getTestData() : data);
    }

    checkLicense(data: {}) {
        this.licenseChange.next(this.getLicense(data));

        if (this.authService.isClient) {
            return true;
        }
        if (this.license.isFailed() && this.router.url !== '/360/admin/license') {
            this.router.navigate(['/', PACKAGE, 'admin', 'license']);
            return false;
        } else if (!this.license.isFailed() && this.router.url === '/360/admin/license') {
            this.router.navigate(['/']);
        } else if (!this.license.upgradeAvailable && this.router.url === '/AV/upgrade') {
            this.router.navigate(['/']);
        }
        return true;
    }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        if (this.license.status) {
            this.router.navigate(['/']);
            return false;
        }

        return true;
    }

    getDefaultMockList() {
        return [];
    }
}

/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "@ngx-translate/core";
import * as i2 from "./no-content.component";
var styles_NoContentComponent = [];
var RenderType_NoContentComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_NoContentComponent, data: {} });
export { RenderType_NoContentComponent as RenderType_NoContentComponent };
export function View_NoContentComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 3, "div", [["id", "i360-404-page"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 2, "h1", [["translate", ""]], null, null, null, null, null)), i0.ɵdid(2, 8536064, null, 0, i1.TranslateDirective, [i1.TranslateService, i0.ElementRef, i0.ChangeDetectorRef], { translate: [0, "translate"] }, null), (_l()(), i0.ɵted(-1, null, ["noContent.title"]))], function (_ck, _v) { var currVal_0 = ""; _ck(_v, 2, 0, currVal_0); }, null); }
export function View_NoContentComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "no-content", [], null, null, null, View_NoContentComponent_0, RenderType_NoContentComponent)), i0.ɵdid(1, 49152, null, 0, i2.NoContentComponent, [], null, null)], null, null); }
var NoContentComponentNgFactory = i0.ɵccf("no-content", i2.NoContentComponent, View_NoContentComponent_Host_0, {}, {}, []);
export { NoContentComponentNgFactory as NoContentComponentNgFactory };

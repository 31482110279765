import * as tslib_1 from "tslib";
import { OverlayContainer } from '@angular/cdk/overlay';
var I360OverlayContainer = /** @class */ (function (_super) {
    tslib_1.__extends(I360OverlayContainer, _super);
    function I360OverlayContainer() {
        return _super.call(this, document) || this;
    }
    I360OverlayContainer.prototype._createContainer = function () {
        _super.prototype._createContainer.call(this);
        var app = document.querySelector('.i360-app app');
        if (app) {
            app.appendChild(this._containerElement);
        }
    };
    return I360OverlayContainer;
}(OverlayContainer));
export { I360OverlayContainer };

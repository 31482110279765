/*

This program is free software: you can redistribute it and/or modify it under 
the terms of the GNU General Public License as published by 
the Free Software Foundation, either version 3 of the License, 
or (at your option) any later version.


This program is distributed in the hope that it will be useful, 
but WITHOUT ANY WARRANTY; without even the implied warranty of 
MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  
See the GNU General Public License for more details.


You should have received a copy of the GNU General Public License
 along with this program.  If not, see <https://www.gnu.org/licenses/>.

Copyright © 2019 Cloud Linux Software Inc.

This software is also available under ImunifyAV commercial license,
see <https://www.imunify360.com/legal/eula>
*/
import '../styles/global/app.style.scss';
import 'hammerjs';

import { AppState } from 'app/core/app.service';
import { ErrorHandler, NgModule } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { TranslateHttpLoader } from 'app/utils/translate/TranslateHttpLoader';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { RootComponent } from 'app/components/root/root.component';
import { I360TranslateModule } from 'app/utils/translate/translate.module';
import { AppRoutingModule } from 'app/core/app-routing.module';
import { AuthService } from 'app/services/auth';
import { getWindow, WindowToken } from 'app/core/window';
import { GestureConfig, MAT_LABEL_GLOBAL_OPTIONS } from '@angular/material/core';
import { DomSanitizer, HAMMER_GESTURE_CONFIG } from '@angular/platform-browser';
import { OverlayContainer } from '@angular/cdk/overlay';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { RootErrorHandler } from 'app/services/sentry';
import { I360OverlayContainer } from 'app/utils/overlay-container';
import { UrlInterceptor } from 'app/interceptors/url.interceptor';
import { JsonHeaderInterceptor } from 'app/interceptors/json-header.interceptor';
import { Panel } from 'app/utils/panel';
import { I360NoContentModule } from 'app/components/no-content/no-content.module';
import { MatIconRegistry } from '@angular/material/icon';
import { applyCsrfStrategy, enableSvgIcons } from 'app/core/misc';
import { SentryInterceptor } from 'app/interceptors/sentry.interceptor';
import { ImunifyStatusInterceptor } from 'app/interceptors/imunify-status.interceptor';
import { ConfigRef } from 'app/core/config-ref';

@NgModule({
    bootstrap: [ RootComponent ],
    declarations: [
        RootComponent,
    ],
    imports: [
        AppRoutingModule,
        BrowserAnimationsModule,
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useClass: TranslateHttpLoader,
            },
        }),
        I360TranslateModule.forRoot(),
        HttpClientModule,
        I360NoContentModule,
    ],
    providers: [
        AppState,
        ConfigRef,
        Panel,
        AuthService,
        { provide: WindowToken, useFactory: getWindow },
        { provide: MAT_LABEL_GLOBAL_OPTIONS , useValue: { float: 'never' }},
        { provide: OverlayContainer, useClass: I360OverlayContainer},
        { provide: HTTP_INTERCEPTORS, useClass: UrlInterceptor, multi: true},
        { provide: HTTP_INTERCEPTORS, useClass: JsonHeaderInterceptor, multi: true},
        { provide: HTTP_INTERCEPTORS, useClass: SentryInterceptor, multi: true},
        { provide: HTTP_INTERCEPTORS, useClass: ImunifyStatusInterceptor, multi: true},
        RootErrorHandler,
        { provide: ErrorHandler, useExisting: RootErrorHandler},
        { provide: HAMMER_GESTURE_CONFIG, useClass: GestureConfig },
    ],
})
export class AppModule {
    constructor(iconRegistry: MatIconRegistry,
                sanitizer: DomSanitizer,
                panel: Panel,
                appState: AppState) {
        if (panel.isPlesk) {
            applyCsrfStrategy();
        }
        enableSvgIcons(iconRegistry, appState, sanitizer);
    }
}

/*

This program is free software: you can redistribute it and/or modify it under 
the terms of the GNU General Public License as published by 
the Free Software Foundation, either version 3 of the License, 
or (at your option) any later version.


This program is distributed in the hope that it will be useful, 
but WITHOUT ANY WARRANTY; without even the implied warranty of 
MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  
See the GNU General Public License for more details.


You should have received a copy of the GNU General Public License
 along with this program.  If not, see <https://www.gnu.org/licenses/>.

Copyright © 2019 Cloud Linux Software Inc.

This software is also available under ImunifyAV commercial license,
see <https://www.imunify360.com/legal/eula>
*/
import { RequestParams } from '@imunify360-api/misc';
import { DEFAULT_DATA } from '@imunify360-api/stubs/misc';


export interface DefaultMock<P, R, E extends R> {
    response: {data: E} | ((params: P) => {data: E});
    api(sourceParams): RequestParams<R>;
}

export class Mock {
    static defaultData = DEFAULT_DATA;
    static add<P, R, E extends R>(
        api: (sourceParams: P) => RequestParams<R>,
        response: {data: E},
    ): DefaultMock<P, R, E> {
        return {api, response};
    }
    static addFn<P, R, E extends R> (
        api: (sourceParams: P) => RequestParams<R>,
        getResponse: (sourceParams: P) => {data: E},
    ): DefaultMock<P, R, E> {
        return {api, response: getResponse};
    }
}

/*

This program is free software: you can redistribute it and/or modify it under 
the terms of the GNU General Public License as published by 
the Free Software Foundation, either version 3 of the License, 
or (at your option) any later version.


This program is distributed in the hope that it will be useful, 
but WITHOUT ANY WARRANTY; without even the implied warranty of 
MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  
See the GNU General Public License for more details.


You should have received a copy of the GNU General Public License
 along with this program.  If not, see <https://www.gnu.org/licenses/>.

Copyright © 2019 Cloud Linux Software Inc.

This software is also available under ImunifyAV commercial license,
see <https://www.imunify360.com/legal/eula>
*/
import { AbstractService } from 'app/services/abstract-service';
import { InstallationAPI } from '@imunify360-api/installation';
import { Injectable } from '@angular/core';
import { responses } from '@imunify360-api/stubs/installation';
import { Mock } from 'app/services/mock';

@Injectable({
    providedIn: 'root',
})
export class InstallationBackendService extends AbstractService {
    status = this.rxServiceRequest(InstallationAPI.status);
    start = this.serviceRequest(InstallationAPI.start);

    getDefaultMockList() {
        return !TEST ? [] : [
            Mock.add(InstallationAPI.status, responses.pieceOfLog),
            Mock.add(InstallationAPI.start, responses.serviceFailedToInstall),
        ];
    }
}

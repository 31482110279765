import * as tslib_1 from "tslib";
import { TranslateService } from 'app/utils/translate/translate.service';
import * as i0 from "@angular/core";
import * as i1 from "../utils/translate/translate.service";
var NotificationsService = /** @class */ (function () {
    function NotificationsService(translate) {
        this.translate = translate;
        this.notifications = [];
    }
    NotificationsService.prototype.success = function (content, title, params) {
        if (title === void 0) { title = null; }
        return this.add(title || "notifications.success", content, 'success', params);
    };
    NotificationsService.prototype.error = function (content, title, params) {
        if (title === void 0) { title = null; }
        return this.add(title || "notifications.error", content, 'error', params);
    };
    NotificationsService.prototype.warning = function (content, title, params) {
        if (title === void 0) { title = null; }
        return this.error(content, title || "notifications.warning", params);
    };
    NotificationsService.prototype.remove = function (item) {
        var _this = this;
        this.notifications = this.notifications.filter(function (i) { return !_this.equal(i, item); });
    };
    NotificationsService.prototype.add = function (title, content, type, params) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var e_1, _a, item, _b, _c, _d, _e, i;
            return tslib_1.__generator(this, function (_f) {
                switch (_f.label) {
                    case 0:
                        _b = Notification.bind;
                        _c = {};
                        return [4 /*yield*/, this.translate.t(title, params)];
                    case 1:
                        _c.title = _f.sent();
                        return [4 /*yield*/, this.translate.t(content, params)];
                    case 2:
                        item = new (_b.apply(Notification, [void 0, (_c.content = _f.sent(),
                                _c.type = type,
                                _c)]))();
                        try {
                            for (_d = tslib_1.__values(this.notifications), _e = _d.next(); !_e.done; _e = _d.next()) {
                                i = _e.value;
                                if (this.equal(i, item)) {
                                    i.counter++;
                                    i.ticks = 0;
                                    return [2 /*return*/];
                                }
                            }
                        }
                        catch (e_1_1) { e_1 = { error: e_1_1 }; }
                        finally {
                            try {
                                if (_e && !_e.done && (_a = _d.return)) _a.call(_d);
                            }
                            finally { if (e_1) throw e_1.error; }
                        }
                        item.counter = 1;
                        this.notifications.push(item);
                        return [2 /*return*/];
                }
            });
        });
    };
    NotificationsService.prototype.equal = function (a, b) {
        return a.type === b.type
            && a.title === b.title
            && a.content === b.content;
    };
    NotificationsService.ngInjectableDef = i0.defineInjectable({ factory: function NotificationsService_Factory() { return new NotificationsService(i0.inject(i1.TranslateService)); }, token: NotificationsService, providedIn: "root" });
    return NotificationsService;
}());
export { NotificationsService };
var Notification = /** @class */ (function () {
    function Notification(item) {
        this.counter = 0;
        this.ticks = 0;
        this.type = item.type;
        this.title = item.title;
        this.content = item.content;
    }
    return Notification;
}());
export { Notification };

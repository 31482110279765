/*

This program is free software: you can redistribute it and/or modify it under
the terms of the GNU General Public License as published by
the Free Software Foundation, either version 3 of the License,
or (at your option) any later version.


This program is distributed in the hope that it will be useful,
but WITHOUT ANY WARRANTY; without even the implied warranty of
MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.
See the GNU General Public License for more details.


You should have received a copy of the GNU General Public License
 along with this program.  If not, see <https://www.gnu.org/licenses/>.

Copyright © 2019 Cloud Linux Software Inc.

This software is also available under ImunifyAV commercial license,
see <https://www.imunify360.com/legal/eula>
*/
/*
 * Angular bootstraping
 */
import 'bootstrap-sass'; // FIXME: bootstrap
import { decorateModuleRef } from 'app/core/environment';
import { bootstrapApp, loadTestInterceptor } from 'app/core/misc';
import * as __NgCli_bootstrap_1 from "./app/core/app.module.ngfactory";
import * as __NgCli_bootstrap_2 from "@angular/platform-browser";
if (TEST) {
    loadTestInterceptor();
}
/*
 * Bootstrap our Angular app with a top level NgModule
 *
 * We access i360role when DomContentLoaded event is emitted, so all scripts in page, added
 * on server side, has been executed, and i360role is 100% defined.
 *
 * We need 'require' to use webpack cache of assetsPath variable. If we use getAssetsPath
 * there may be a risk to have different assetsPath in different places
 * (there is one more place where it is used)
 */
export function main() {
    __webpack_public_path__ = require('./assets').assetsPath;
    return __NgCli_bootstrap_2.platformBrowser()
        .bootstrapModuleFactory(__NgCli_bootstrap_1.AppModuleNgFactory)
        .then(decorateModuleRef)
        .catch(function (err) { return console.error(err); });
}
bootstrapApp(main);

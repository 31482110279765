/*

This program is free software: you can redistribute it and/or modify it under 
the terms of the GNU General Public License as published by 
the Free Software Foundation, either version 3 of the License, 
or (at your option) any later version.


This program is distributed in the hope that it will be useful, 
but WITHOUT ANY WARRANTY; without even the implied warranty of 
MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  
See the GNU General Public License for more details.


You should have received a copy of the GNU General Public License
 along with this program.  If not, see <https://www.gnu.org/licenses/>.

Copyright © 2019 Cloud Linux Software Inc.

This software is also available under ImunifyAV commercial license,
see <https://www.imunify360.com/legal/eula>
*/
/*
 * Angular bootstraping
 */
import 'bootstrap-sass'; // FIXME: bootstrap
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { decorateModuleRef } from 'app/core/environment';
import { AppModule } from 'app/core/app.module';
import { bootstrapApp, loadTestInterceptor } from 'app/core/misc';

if (TEST) {
    loadTestInterceptor();
}

declare let __webpack_public_path__: string;

/*
 * Bootstrap our Angular app with a top level NgModule
 *
 * We access i360role when DomContentLoaded event is emitted, so all scripts in page, added
 * on server side, has been executed, and i360role is 100% defined.
 *
 * We need 'require' to use webpack cache of assetsPath variable. If we use getAssetsPath
 * there may be a risk to have different assetsPath in different places
 * (there is one more place where it is used)
 */
export function main(): Promise<any> {
    __webpack_public_path__ = require('./assets').assetsPath;
    return platformBrowserDynamic()
        .bootstrapModule(AppModule)
        .then(decorateModuleRef)
        .catch(err => console.error(err));
}

bootstrapApp(main);

import * as tslib_1 from "tslib";
import { LicenseType } from '../license';
export var DEFAULT_DATA = {
    strategy: 'PRIMARY_IDS',
    version: 'testing',
    eula: null,
    license: {
        id: '123',
        license_type: LicenseType.imunify360,
        user_count: 5,
        message: '',
        expiration: null,
        user_limit: 2147483647,
        status: true,
        redirect_url: 'https://cln.cloudlinux.com/clweb/public/imconv.xhtml?id=qwertyuiop',
    },
};
export var noItemsResponse = { data: DEFAULT_DATA };
export var emptyListResponse = { data: tslib_1.__assign({}, DEFAULT_DATA, { items: [] }) };
export var emptyResponse = { data: tslib_1.__assign({}, DEFAULT_DATA, { items: null }) };

import * as tslib_1 from "tslib";
/*

This program is free software: you can redistribute it and/or modify it under
the terms of the GNU General Public License as published by
the Free Software Foundation, either version 3 of the License,
or (at your option) any later version.


This program is distributed in the hope that it will be useful,
but WITHOUT ANY WARRANTY; without even the implied warranty of
MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.
See the GNU General Public License for more details.


You should have received a copy of the GNU General Public License
 along with this program.  If not, see <https://www.gnu.org/licenses/>.

Copyright © 2019 Cloud Linux Software Inc.

This software is also available under ImunifyAV commercial license,
see <https://www.imunify360.com/legal/eula>
*/
import { AgentStatus } from '@imunify360-api/misc';
import { Injector } from '@angular/core';
import { Router } from '@angular/router';
import { of, EMPTY, Observable } from 'rxjs';
import { catchError, share, switchMap } from 'rxjs/operators';
import { Package } from '@imunify360-api/license';
import { HttpHandler, HttpRequest, HttpResponse, } from '@angular/common/http';
import { AppState } from 'app/core/app.service';
import { postOnly } from './utils';
import { InstallationBackendService, } from 'app/components/installation/installation-backend.service';
import { AuthService } from 'app/services/auth';
var systemErrorMessage = 'System error 2: No such file or directory';
var importErrorMessage = 'ImportError: No module named';
var logTabUrl = "/installation/progress/log";
var slidesTabUrl = "/installation/progress/slides";
var ImunifyStatusInterceptor = /** @class */ (function () {
    function ImunifyStatusInterceptor(router, injector, appState) {
        this.router = router;
        this.injector = injector;
        this.appState = appState;
    }
    ImunifyStatusInterceptor.prototype.intercept = function (req, next) {
        var _this = this;
        return next.handle(req).pipe(catchError(function (error) {
            try {
                if (error.error.includes(importErrorMessage)) {
                    var result = _this.updateAndCheckStatus(AgentStatus.INSTALLING);
                    if (result)
                        return result;
                }
                if (error.error.includes(systemErrorMessage)) {
                    if (_this.injector.get(AuthService).isClient) {
                        _this.updateAndCheckStatus(AgentStatus.INSTALLING);
                        return EMPTY;
                    }
                    if (!_this.getInstallationStatus) {
                        _this.getInstallationStatus = _this.injector
                            .get(InstallationBackendService).status({
                            offset: 0,
                            limit: 1,
                        }).pipe(share());
                    }
                    _this.getInstallationStatus.subscribe();
                    return EMPTY;
                }
            }
            catch (_a) { }
            throw error;
        }), switchMap(function (event) {
            if (event instanceof HttpResponse) {
                var res = event.body;
                var status_1 = res.status || AgentStatus.OK;
                var result = _this.updateAndCheckStatus(status_1);
                if (result)
                    return result;
            }
            return of(event);
        }));
    };
    ImunifyStatusInterceptor.prototype.reload = function () {
        location.reload();
    };
    /**
     * This method was extracted from previous one because one more place of usage was needed
     * `return EMPTY` means return from parent method. And `return;` looks like analog of `break;`
     */
    ImunifyStatusInterceptor.prototype.updateAndCheckStatus = function (status) {
        this.appState.imunifyStatus = status;
        switch (this.appState.imunifyStatus) {
            case AgentStatus.OK:
                if (this.router.url.startsWith('/installation')) {
                    this.router.navigate(['/'], { replaceUrl: true });
                    this.reload();
                }
                return;
            case AgentStatus.INSTALLING:
                if (!this.router.url.startsWith('/installation')) {
                    this.router.navigateByUrl(slidesTabUrl, { replaceUrl: true });
                    return EMPTY;
                }
                return;
            case AgentStatus.NOT_INSTALLED:
                if (this.router.url !== '/installation') {
                    if (this.injector.get(AuthService).isClient) {
                        this.router.navigateByUrl(slidesTabUrl, { replaceUrl: true });
                    }
                    else {
                        this.router.navigateByUrl('/installation', { replaceUrl: true });
                    }
                    return EMPTY;
                }
                return;
            case AgentStatus.FAILED_TO_INSTALL:
                if (PACKAGE === Package.imunify360) {
                    if (this.router.url !== logTabUrl) {
                        if (this.injector.get(AuthService).isClient) {
                            this.router.navigateByUrl(slidesTabUrl, { replaceUrl: true });
                        }
                        else {
                            this.router.navigateByUrl(logTabUrl, { replaceUrl: true });
                        }
                        return EMPTY;
                    }
                }
                return;
            case AgentStatus.STOPPED:
                this.router.navigate(['installation', 'stopped-service'], {
                    replaceUrl: true,
                });
                return EMPTY;
            default:
                return;
        }
    };
    tslib_1.__decorate([
        postOnly,
        tslib_1.__metadata("design:type", Function),
        tslib_1.__metadata("design:paramtypes", [HttpRequest, HttpHandler]),
        tslib_1.__metadata("design:returntype", Observable)
    ], ImunifyStatusInterceptor.prototype, "intercept", null);
    return ImunifyStatusInterceptor;
}());
export { ImunifyStatusInterceptor };

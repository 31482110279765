import * as tslib_1 from "tslib";
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { Inject, Optional, SkipSelf } from '@angular/core';
jQuery.noConflict();
if (typeof Prototype !== 'undefined' && Prototype.BrowserFeatures.ElementExtensions) {
    var disablePrototypeJS = function (method, pluginsToDisable) {
        pluginsToDisable.each(function (plugin) {
            jQuery(window).on(method + '.bs.' + plugin, function (event) {
                event.target[method] = undefined;
                setTimeout(function () {
                    delete event.target[method];
                }, 0);
            });
        });
    }, pluginsToDisable = ['collapse', 'dropdown', 'modal', 'tooltip', 'popover', 'tab'];
    disablePrototypeJS('show', pluginsToDisable);
    disablePrototypeJS('hide', pluginsToDisable);
}
export function loadTestInterceptor() {
    window.i360Test = {
        type: 'e2e',
        disableNews: true,
        disableStats: true,
        testMode: {},
        lastRequest: {},
        requests: {},
        requestsHistory: [],
        mock: [],
        jq: jQuery,
    };
    jQuery(function () {
        // remove header in plesk
        // it blocks clicks in navigation tests
        jQuery('.page-header-wrapper').css({ visibility: 'hidden' });
        // remove breadcrumbs in cpanel
        // they block clicks to elements on the top of the screen
        jQuery('#navigation').css({ visibility: 'hidden' });
    });
}
export function bootstrapApp(main) {
    function __domReadyHandler() {
        document.removeEventListener('DOMContentLoaded', __domReadyHandler);
        main();
    }
    switch (document.readyState) {
        case 'loading':
            document.addEventListener('DOMContentLoaded', __domReadyHandler);
            break;
        case 'interactive':
        case 'complete':
        default:
            main();
    }
}
export function enableSvgIcons(iconRegistry, appState, sanitizer) {
    var e_1, _a;
    var icons = [
        'fish',
        'aquarium',
        'add-ip',
        'ignore-all',
        'broom',
        'bell',
        'atoms',
        'chip',
        'update',
        'clock',
        'dmc',
        'full-access',
        'icon-exclamation',
        'logo-grey',
        'ellipsis',
        'shark',
        'logo-imunify',
        'new-features-management',
        'profile',
        'support',
        'logo-imunifyAV',
        'logo-imunifyAVPlus',
        'key',
        'icon-question',
        'icon-delete',
        'icon-add',
        'icon-key',
        'icon-bell-in-circle',
        'icon-info',
        'dashboard-no-data-found',
        'read-more',
        'events-notifications',
        'check-circle',
        'shield',
        'undraw-server-down',
        'upgrade-by-key',
    ];
    try {
        for (var icons_1 = tslib_1.__values(icons), icons_1_1 = icons_1.next(); !icons_1_1.done; icons_1_1 = icons_1.next()) {
            var icon = icons_1_1.value;
            iconRegistry.addSvgIcon(icon, sanitizer.bypassSecurityTrustResourceUrl(appState.imagePath + "/" + icon + ".svg"));
        }
    }
    catch (e_1_1) { e_1 = { error: e_1_1 }; }
    finally {
        try {
            if (icons_1_1 && !icons_1_1.done && (_a = icons_1.return)) _a.call(icons_1);
        }
        finally { if (e_1) throw e_1.error; }
    }
}
var AngularIssues18894Crutch = /** @class */ (function () {
    function AngularIssues18894Crutch(interceptors) {
        this.interceptors = interceptors;
    }
    AngularIssues18894Crutch.prototype.intercept = function (req, next) {
        return (this.interceptors || []).reduceRight(function (next, interceptor) { return ({
            // HttpInterceptorHandler not exported, so use duck typing
            handle: function (req) {
                return interceptor.intercept(req, next);
            },
        }); }, next).handle(req);
    };
    return AngularIssues18894Crutch;
}());
export { AngularIssues18894Crutch };
export function angular18894CrutchFactory(interceptors) {
    return new AngularIssues18894Crutch(interceptors);
}
export var angular18894CrutchProvider = {
    // https://github.com/angular/angular/issues/18894
    provide: HTTP_INTERCEPTORS,
    multi: true,
    useFactory: angular18894CrutchFactory,
    deps: [[new SkipSelf, new Optional, new Inject(HTTP_INTERCEPTORS)]],
};
/**
 * A hack to prevent Plesk from adding a csrf header to some requests.
 */
export function applyCsrfStrategy() {
    var csrfTokenMeta = document.getElementById('forgery_protection_token');
    var urls = ['https://api.imunify360.com', 'https://sentry.cloudlinux.com'];
    var oldSend = XMLHttpRequest.prototype.send;
    XMLHttpRequest.prototype.send = function () {
        var _this = this;
        var args = [];
        for (var _i = 0; _i < arguments.length; _i++) {
            args[_i] = arguments[_i];
        }
        var skipHeader = false;
        try {
            if (urls.find(function (url) { return _this.__zone_symbol__xhrURL.startsWith(url); })) {
                skipHeader = true; // this request shouldn't contain csrf header
            }
        }
        catch (e) { }
        if (skipHeader && csrfTokenMeta) {
            csrfTokenMeta.setAttribute('id', 'temporary-changed-for-sentry-request');
            var retVal = oldSend.apply(this, args);
            csrfTokenMeta.setAttribute('id', 'forgery_protection_token');
            return retVal;
        }
        else {
            return oldSend.apply(this, args);
        }
    };
}

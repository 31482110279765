/*

This program is free software: you can redistribute it and/or modify it under 
the terms of the GNU General Public License as published by 
the Free Software Foundation, either version 3 of the License, 
or (at your option) any later version.


This program is distributed in the hope that it will be useful, 
but WITHOUT ANY WARRANTY; without even the implied warranty of 
MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  
See the GNU General Public License for more details.


You should have received a copy of the GNU General Public License
 along with this program.  If not, see <https://www.gnu.org/licenses/>.

Copyright © 2019 Cloud Linux Software Inc.

This software is also available under ImunifyAV commercial license,
see <https://www.imunify360.com/legal/eula>
*/
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { NoContentComponent } from 'app/components/no-content/no-content.component';
import { RoleGuard } from 'app/core/role-guard';
import { AuthService } from 'app/services/auth';
import { Package } from '@imunify360-api/license';
import { PackageGuard } from 'app/core/package.guard';

const routes: Routes = [
    {
        path: '',
        pathMatch: 'full',
        canActivate: [PackageGuard],
        component: NoContentComponent,
    },
    {
        path: Package.imunify360,
        canActivate: [PackageGuard],
        canLoad: [PackageGuard],
        children: [
            {
                path: '',
                pathMatch: 'full',
                canActivate: [RoleGuard],
                component: NoContentComponent,
            },
            {
                path: 'admin',
                loadChildren: 'app/core/admin.module#AdminModule',
                canActivate: [RoleGuard],
                canLoad: [RoleGuard],
            },
            {
                path: 'client',
                loadChildren: 'app/core/client.module#ClientModule',
                canActivate: [RoleGuard],
                canLoad: [RoleGuard],
            },
        ],
    },
    {
        path: 'installation',
        loadChildren: 'app/components/installation/installation.module#InstallationModule',
    },
    {
        path: Package.imunifyAV,
        canActivate: [PackageGuard],
        canLoad: [PackageGuard],
        loadChildren: 'app/components/av/av.module#AvModule',
    },
    {
        path: 'login',
        loadChildren: 'app/components/login/login.module#LoginModule',
        canLoad: [AuthService],
    },
    {
        path: 'showcase',
        loadChildren: 'app/components/showcase/showcase.module#ShowcaseModule',
    },
    {path: '**', component: NoContentComponent},
];

@NgModule({
    imports: [
        RouterModule.forRoot(routes, {useHash: true}),
    ],
    exports: [RouterModule],
    providers: [],
})
export class AppRoutingModule { }

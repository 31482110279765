import * as tslib_1 from "tslib";
import { XHR } from 'app/utils/xhr';
import { timer } from 'rxjs';
import { map, mapTo } from 'rxjs/operators';
import { Mock } from 'app/services/mock';
import { testMode } from 'app/services/misc';
var AbstractService = /** @class */ (function () {
    function AbstractService(xhr) {
        this.xhr = xhr;
    }
    AbstractService.prototype.getMock = function (requestParams, sourceParams) {
        // DO NOT REPLACE THIS WITH if(!TEST) { return null; }
        // positive condition is needed for tree-shaking of `Mock`
        if (TEST && testMode(this.constructor.name) && requestParams.method !== void 0) {
            var method = requestParams.method.join(' ');
            var hasMock = !!window.i360Test.mock[method];
            var mock = hasMock ? window.i360Test.mock[method]
                : this.getDefaultMock(requestParams, sourceParams);
            var result = tslib_1.__assign({ result: 'success', messages: [] }, mock, {
                data: tslib_1.__assign({}, Mock.defaultData, mock.data),
            });
            console.log("XHR request:", requestParams);
            console.log("XHR " + (hasMock ? '' : 'default ') + "mock: %c" + method, "color:green", result);
            // simulating asynchronous run
            return timer(0).pipe(mapTo(result));
        }
        return null;
    };
    AbstractService.prototype.getDefaultMock = function (requestParams, sourceParams) {
        var e_1, _a;
        if (!TEST) {
            return null;
        }
        try {
            for (var _b = tslib_1.__values(this.getDefaultMockList()), _c = _b.next(); !_c.done; _c = _b.next()) {
                var item = _c.value;
                var source = void 0;
                try {
                    source = item.api(sourceParams);
                }
                catch (_d) {
                    continue;
                } // inappropriate sourceParams for this api, skip
                if (source.method !== void 0 && requestParams.method !== void 0
                    && source.method.join(' ') === requestParams.method.join(' ')) {
                    return item.response instanceof Function
                        ? item.response(sourceParams)
                        : item.response;
                }
            }
        }
        catch (e_1_1) { e_1 = { error: e_1_1 }; }
        finally {
            try {
                if (_c && !_c.done && (_a = _b.return)) _a.call(_b);
            }
            finally { if (e_1) throw e_1.error; }
        }
        return { data: {} };
    };
    /**
     * Wraps API method to create a service method.
     */
    AbstractService.prototype.serviceRequest = function (api, notifyOnWarningDefault, notifyOnErrorDefault) {
        var _this = this;
        if (notifyOnWarningDefault === void 0) { notifyOnWarningDefault = true; }
        if (notifyOnErrorDefault === void 0) { notifyOnErrorDefault = true; }
        // NOTE: we cannot use "...args" instead of "parameters", because of this:
        // https://github.com/Microsoft/TypeScript/issues/5453
        // So, we won't be able to pass types unless we do smth like this:
        // https://github.com/Microsoft/TypeScript/blob/master/lib/lib.es2015.promise.d.ts#L41-L113
        return function (params, notifyOnWarning, notifyOnError) {
            if (notifyOnWarning === void 0) { notifyOnWarning = notifyOnWarningDefault; }
            if (notifyOnError === void 0) { notifyOnError = notifyOnErrorDefault; }
            var requestParams = api(params);
            return _this.xhr.post(requestParams, function () { return _this.getMock(requestParams, params); }, notifyOnWarning, notifyOnError).toPromise();
        };
    };
    AbstractService.prototype.rxServiceRequest = function (api, notifyOnWarningDefault, notifyOnErrorDefault) {
        var _this = this;
        if (notifyOnWarningDefault === void 0) { notifyOnWarningDefault = true; }
        if (notifyOnErrorDefault === void 0) { notifyOnErrorDefault = true; }
        return function (params, notifyOnWarning, notifyOnError) {
            if (notifyOnWarning === void 0) { notifyOnWarning = notifyOnWarningDefault; }
            if (notifyOnError === void 0) { notifyOnError = notifyOnErrorDefault; }
            var requestParams = api(params);
            return _this.xhr.post(requestParams, function () { return _this.getMock(requestParams, params); }, notifyOnWarning, notifyOnError);
        };
    };
    /**
     * converts items in AgentResponse from list of P to list of itemClass
     */
    AbstractService.prototype.wrap = function (serviceMethod, itemClass) {
        return function (params) {
            return serviceMethod(params).pipe(map(function (_a) {
                var data = _a.data, result = _a.result, messages = _a.messages;
                return ({
                    data: {
                        items: data.items.map(function (item) { return new itemClass(item); }),
                        max_count: data.max_count,
                        version: data.version,
                        strategy: data.strategy,
                        license: data.license,
                        eula: data.eula,
                    },
                    result: result,
                    messages: messages,
                });
            }));
        };
    };
    return AbstractService;
}());
export { AbstractService };

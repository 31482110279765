/*

This program is free software: you can redistribute it and/or modify it under 
the terms of the GNU General Public License as published by 
the Free Software Foundation, either version 3 of the License, 
or (at your option) any later version.


This program is distributed in the hope that it will be useful, 
but WITHOUT ANY WARRANTY; without even the implied warranty of 
MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  
See the GNU General Public License for more details.


You should have received a copy of the GNU General Public License
 along with this program.  If not, see <https://www.gnu.org/licenses/>.

Copyright © 2019 Cloud Linux Software Inc.

This software is also available under ImunifyAV commercial license,
see <https://www.imunify360.com/legal/eula>
*/
import { ResponseDataExceptItems } from '../misc';
import { LicenseType } from '../license';

export const DEFAULT_DATA: ResponseDataExceptItems = {
    strategy: 'PRIMARY_IDS',
    version: 'testing',
    eula: null,
    license: {
        id: '123',
        license_type: LicenseType.imunify360,
        user_count: 5,
        message: '',
        expiration: null,
        user_limit: 2147483647,
        status: true,
        redirect_url: 'https://cln.cloudlinux.com/clweb/public/imconv.xhtml?id=qwertyuiop',
    },
};
export const noItemsResponse = {data: DEFAULT_DATA};
export const emptyListResponse = {data: {...DEFAULT_DATA, items: []}};
export const emptyResponse = {data: {...DEFAULT_DATA, items: null}};

import * as tslib_1 from "tslib";
var RequestParams = /** @class */ (function () {
    function RequestParams(method, params) {
        if (params === void 0) { params = {}; }
        this.method = method;
        this.params = params;
    }
    RequestParams.prototype.toString = function () {
        return JSON.stringify(this);
    };
    return RequestParams;
}());
export { RequestParams };
var ExtendedRequestParams = /** @class */ (function (_super) {
    tslib_1.__extends(ExtendedRequestParams, _super);
    function ExtendedRequestParams(command, method, params) {
        if (method === void 0) { method = []; }
        if (params === void 0) { params = {}; }
        var _this = _super.call(this, method, params) || this;
        _this.command = command;
        _this.method = method;
        _this.params = params;
        return _this;
    }
    return ExtendedRequestParams;
}(RequestParams));
export { ExtendedRequestParams };
export var Result;
(function (Result) {
    Result["ERROR"] = "error";
    Result["WARNING"] = "warnings";
    Result["SUCCESS"] = "success";
})(Result || (Result = {}));
export var AgentStatus;
(function (AgentStatus) {
    AgentStatus["OK"] = "running";
    AgentStatus["INSTALLING"] = "installing";
    AgentStatus["NOT_INSTALLED"] = "not_installed";
    AgentStatus["FAILED_TO_INSTALL"] = "failed_to_install";
    AgentStatus["STOPPED"] = "stopped";
})(AgentStatus || (AgentStatus = {}));

/*

This program is free software: you can redistribute it and/or modify it under 
the terms of the GNU General Public License as published by 
the Free Software Foundation, either version 3 of the License, 
or (at your option) any later version.


This program is distributed in the hope that it will be useful, 
but WITHOUT ANY WARRANTY; without even the implied warranty of 
MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  
See the GNU General Public License for more details.


You should have received a copy of the GNU General Public License
 along with this program.  If not, see <https://www.gnu.org/licenses/>.

Copyright © 2019 Cloud Linux Software Inc.

This software is also available under ImunifyAV commercial license,
see <https://www.imunify360.com/legal/eula>
*/
import { Injectable } from '@angular/core';
import { TranslateParams, TranslateService } from 'app/utils/translate/translate.service';


@Injectable({
    providedIn: 'root',
})
export class NotificationsService {
    notifications: Notification[] = [];

    constructor(private translate: TranslateService) {}

    success(content: string, title: string|null = null, params?: TranslateParams) {
        return this.add(title || `notifications.success`, content, 'success', params);
    }

    error(content: string, title: string|null = null, params?: TranslateParams) {
        return this.add(title || `notifications.error`, content, 'error', params);
    }

    warning(content: string, title: string|null = null, params?: TranslateParams) {
        return this.error(content, title || `notifications.warning`, params);
    }

    remove(item: Notification): void {
        this.notifications = this.notifications.filter(i => !this.equal(i, item));
    }

    private async add(title: string,
                      content: string,
                      type: string,
                      params: TranslateParams|undefined): Promise<void> {
        const item = new Notification({
            title: await this.translate.t(title, params),
            content: await this.translate.t(content, params),
            type,
        });

        for (let i of this.notifications) {
            if (this.equal(i, item)) {
                i.counter++;
                i.ticks = 0;
                return;
            }
        }

        item.counter = 1;
        this.notifications.push(item);
    }

    private equal(a: Notification, b: Notification) {
        return a.type === b.type
            && a.title === b.title
            && a.content === b.content;
    }
}

export class Notification {
    type: string;
    title: string;
    content: string;
    counter: number = 0;
    ticks: number = 0;

    constructor(item: {type: string, title: string, content: string}) {
        this.type = item.type;
        this.title = item.title;
        this.content = item.content;
    }
}

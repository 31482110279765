import * as tslib_1 from "tslib";
import { HttpHandler, HttpRequest } from '@angular/common/http';
import { Observable } from 'rxjs';
import { AuthService } from 'app/services/auth';
import { postOnly } from 'app/interceptors/utils';
var UrlInterceptor = /** @class */ (function () {
    function UrlInterceptor(authService) {
        var _this = this;
        this.authService = authService;
        this.action = function () { return _this.authService.isAdmin ? adminAction : clientAction; };
    }
    UrlInterceptor.prototype.intercept = function (req, next) {
        // this code should be present in xhr.
        var newUrl = req.url.startsWith('http') ? req.url : this.action() + req.url;
        return next.handle(req.clone({ url: newUrl }));
    };
    tslib_1.__decorate([
        postOnly,
        tslib_1.__metadata("design:type", Function),
        tslib_1.__metadata("design:paramtypes", [HttpRequest, HttpHandler]),
        tslib_1.__metadata("design:returntype", Observable)
    ], UrlInterceptor.prototype, "intercept", null);
    return UrlInterceptor;
}());
export { UrlInterceptor };

import * as tslib_1 from "tslib";
import { HttpHandler, HttpRequest } from '@angular/common/http';
import { Observable } from 'rxjs';
import { postOnly } from 'app/interceptors/utils';
var JsonHeaderInterceptor = /** @class */ (function () {
    function JsonHeaderInterceptor() {
    }
    JsonHeaderInterceptor.prototype.intercept = function (req, next) {
        var headers;
        if (!(req.body instanceof FormData)) {
            headers = req.headers
                .set('Content-type', 'application/json; charset=utf-8');
        }
        return next.handle(req.clone({ headers: headers }));
    };
    tslib_1.__decorate([
        postOnly,
        tslib_1.__metadata("design:type", Function),
        tslib_1.__metadata("design:paramtypes", [HttpRequest, HttpHandler]),
        tslib_1.__metadata("design:returntype", Observable)
    ], JsonHeaderInterceptor.prototype, "intercept", null);
    return JsonHeaderInterceptor;
}());
export { JsonHeaderInterceptor };

import * as tslib_1 from "tslib";
import { Router, } from '@angular/router';
import * as i0 from "@angular/core";
import * as i1 from "@angular/router";
var PackageGuard = /** @class */ (function () {
    function PackageGuard(router) {
        this.router = router;
    }
    PackageGuard.prototype.canActivate = function (route, state) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                return [2 /*return*/, this.check(route.routeConfig ? route.routeConfig.path : void 0)];
            });
        });
    };
    PackageGuard.prototype.canLoad = function (route, segments) {
        return this.check(route.path);
    };
    PackageGuard.prototype.check = function (path) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!(path !== PACKAGE)) return [3 /*break*/, 2];
                        return [4 /*yield*/, this.router.navigate(['/', PACKAGE], { replaceUrl: true })];
                    case 1:
                        _a.sent();
                        return [2 /*return*/, false];
                    case 2: return [2 /*return*/, true];
                }
            });
        });
    };
    PackageGuard.ngInjectableDef = i0.defineInjectable({ factory: function PackageGuard_Factory() { return new PackageGuard(i0.inject(i1.Router)); }, token: PackageGuard, providedIn: "root" });
    return PackageGuard;
}());
export { PackageGuard };

import * as tslib_1 from "tslib";
import { Router, } from '@angular/router';
import { AuthService } from 'app/services/auth';
import * as i0 from "@angular/core";
import * as i1 from "../services/auth";
import * as i2 from "@angular/router";
var RoleGuard = /** @class */ (function () {
    function RoleGuard(authService, router) {
        this.authService = authService;
        this.router = router;
    }
    RoleGuard.prototype.canActivate = function (route, state) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                return [2 /*return*/, this.check(route.routeConfig ? route.routeConfig.path : void 0)];
            });
        });
    };
    RoleGuard.prototype.canLoad = function (route) {
        return this.check(route.path);
    };
    RoleGuard.prototype.check = function (path) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!(path !== this.authService.role)) return [3 /*break*/, 2];
                        return [4 /*yield*/, this.router.navigate(['/', PACKAGE, this.authService.role], {
                                replaceUrl: true,
                            })];
                    case 1:
                        _a.sent();
                        return [2 /*return*/, false];
                    case 2: return [2 /*return*/, true];
                }
            });
        });
    };
    RoleGuard.ngInjectableDef = i0.defineInjectable({ factory: function RoleGuard_Factory() { return new RoleGuard(i0.inject(i1.AuthService), i0.inject(i2.Router)); }, token: RoleGuard, providedIn: "root" });
    return RoleGuard;
}());
export { RoleGuard };

import * as tslib_1 from "tslib";
import * as parent from '@ngx-translate/core';
import { LANGUAGE_KEY, Panel } from 'app/utils/panel';
import { noop } from 'rxjs';
import * as moment from 'moment';
import { Overlay } from '@angular/cdk/overlay';
import { Directionality } from '@angular/cdk/bidi';
import { map } from 'rxjs/operators';
import * as Raven from 'raven-js';
var translateGetParsedResult = parent.TranslateService.prototype.getParsedResult;
var DEFAULT_LANGUAGE = 'en';
export var PANEL_DEFAULT_LANGUAGE = 'panelLang';
var UntranslatableString = /** @class */ (function () {
    function UntranslatableString(value) {
        this.value = value;
    }
    UntranslatableString.for = function (value) {
        return new this(value || '');
    };
    Object.defineProperty(UntranslatableString.prototype, "length", {
        get: function () {
            return this.value.length;
        },
        enumerable: true,
        configurable: true
    });
    UntranslatableString.prototype.toString = function () {
        return this.value;
    };
    return UntranslatableString;
}());
export { UntranslatableString };
var ignoreSentryPatterns = [
    /^One or more domains provided do not exist.*/,
    /^malware\.onDemand\.status.*/,
    /Path .* should be absolute/,
    /^IP [0-9a-fA-F\.\/\:]* is already in [A-Z]* list/,
    /^Country [A-F]* is already in [A-Z]* list/,
    /^[0-9]*\/[0-9]* ip\(s\) were successfully deleted/,
    /has host bits set$/,
    /^Noop: /,
    /^Authentication failed/,
    /^User name not found/,
    /^Feature '[a-zA-Z]*' is disabled for user/,
    /^\[Errno 104\] Connection reset by peer$/,
    /^Kernel is unsupported/,
    /^Can't delete KernelCare, because it's not installed$/,
    /^Request timeout$/,
    /^Empty response from socket$/,
    /^Request processing error$/,
    /^Licenses limit exceeded$/,
    /^On-demand scan is already running$/,
    /Too many open files/,
    /Connection refused$/,
    /^400 Client Error: BAD REQUEST for url/,
];
parent.TranslateService.prototype.getParsedResult = function (translations, key, interpolateParams) {
    if (key instanceof UntranslatableString) {
        return key.value;
    }
    var result = translateGetParsedResult.call(this, translations, key, interpolateParams);
    if (ENV !== 'test' && result === key
        && !ignoreSentryPatterns.some(function (pattern) { return pattern.test(key); })) {
        var error = {
            reason: 'Can not find translation',
            key: key,
            interpolateParams: interpolateParams,
        };
        console.error(error);
        Raven.captureException(new Error(JSON.stringify(error)));
    }
    return result;
};
var TranslateService = /** @class */ (function () {
    function TranslateService(translate, panel, document) {
        var _this = this;
        this.translate = translate;
        this.panel = panel;
        this.document = document;
        this.langs = [{
                code: 'en',
                moment: 'en',
                name: 'English',
            }, {
                code: 'es',
                moment: 'es',
                name: 'Español',
            }, {
                code: 'ru',
                moment: 'ru',
                name: 'Русский',
            }, {
                code: 'ja',
                moment: 'ja',
                name: '日本語',
            }, {
                code: 'de',
                moment: 'de',
                name: 'Deutsch',
            }, {
                code: 'fr',
                moment: 'fr',
                name: 'Français',
            }, {
                code: 'it',
                moment: 'it',
                name: 'Italiano',
            }, {
                code: 'nl',
                moment: 'nl',
                name: 'Nederlands',
            }, {
                code: 'pt-br',
                moment: 'pt-br',
                name: 'Português',
            }, {
                code: 'tr',
                moment: 'tr',
                name: 'Türkçe',
            }, {
                code: 'zh-cn',
                moment: 'zh-cn',
                name: '简体中文',
            }];
        this.rtlLangs = [
        // 'es',
        ];
        this.html = this.document.querySelector('html');
        this.dirContainer = this.html.getElementsByClassName('i360-app')[0];
        this.panelLang = this.panel.getLang(this.document);
        this.initLanguages();
        var create = Overlay.prototype.create;
        var dir;
        Object.defineProperty(Directionality.prototype, 'value', {
            get: function () { return dir; },
            set: noop,
        });
        Object.defineProperty(Directionality.prototype, 'change', {
            get: function () { return dirChanged; },
            set: noop,
        });
        var dirChanged = translate.onLangChange.pipe(map(function (_a) {
            var lang = _a.lang;
            return _this.getDir(lang);
        }));
        dirChanged.subscribe(function (d) { return dir = d; });
        Overlay.prototype.create = function (config) {
            var ref = create.call(this, config);
            Object.defineProperty(ref._config, 'direction', {
                get: function () { return dir; },
                set: noop,
            });
            return ref;
        };
        this.translate.onLangChange.subscribe(function (_a) {
            var lang = _a.lang;
            return _this.setMomentLocale(lang);
        });
        // if `<defaultLang>.i18n` file is finished loading after `<currentLang>.i18n` -
        // moment global locale wil get reset during import (due to `getSetGlobalLocale` call in
        // `defineLocale`)
        // We need to force it back after the import is done:
        this.translate.onDefaultLangChange.subscribe(function () { return _this.setMomentLocale(_this.currentLang); });
    }
    TranslateService.prototype.getDir = function (lang) {
        return this.rtlLangs.includes(lang) ? 'rtl' : 'ltr';
    };
    TranslateService.prototype.use = function (lang) {
        localStorage.setItem(LANGUAGE_KEY, lang);
        this.logicalLang = lang;
        if (lang === PANEL_DEFAULT_LANGUAGE || !lang) {
            lang = this.panelLang;
        }
        this.currentLang = this.getAppropriateLang(lang, this.langs.map(function (lang) { return lang.code; }), DEFAULT_LANGUAGE);
        this.translate.use(this.currentLang);
        this.ensureRtl(this.currentLang);
    };
    TranslateService.prototype.setMomentLocale = function (lang) {
        var item = this.langs.find(function (i) { return i.code === lang; });
        lang = item ? item.moment : lang;
        moment.locale(lang);
    };
    TranslateService.prototype.t = function (key, interpolateParams) {
        return this.translate.get(key, interpolateParams).toPromise();
    };
    TranslateService.prototype.stream = function (key, interpolateParams) {
        return this.translate.stream(key, interpolateParams);
    };
    TranslateService.prototype.getAppropriateLang = function (lang, langs, defaultLang) {
        if (langs.includes(lang)) {
            return lang;
        }
        var _a = tslib_1.__read(lang.split('-'), 2), standardLang = _a[0], dialect = _a[1];
        if (dialect && langs.includes(standardLang)) {
            return standardLang;
        }
        return defaultLang;
    };
    TranslateService.prototype.initLanguages = function () {
        var currentleng = localStorage.getItem(LANGUAGE_KEY) || '';
        this.translate.addLangs(this.langs.map(function (lang) { return lang.code; }));
        this.translate.setDefaultLang(DEFAULT_LANGUAGE);
        this.use(currentleng);
    };
    TranslateService.prototype.ensureRtl = function (lang) {
        if (this.html) {
            var dir = this.getDir(lang);
            this.html.classList.remove('rtl', 'ltr');
            this.html.classList.add(dir);
            if (this.dirContainer) {
                this.dirContainer.dir = dir;
            }
        }
    };
    return TranslateService;
}());
export { TranslateService };

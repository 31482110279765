/*

This program is free software: you can redistribute it and/or modify it under 
the terms of the GNU General Public License as published by 
the Free Software Foundation, either version 3 of the License, 
or (at your option) any later version.


This program is distributed in the hope that it will be useful, 
but WITHOUT ANY WARRANTY; without even the implied warranty of 
MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  
See the GNU General Public License for more details.


You should have received a copy of the GNU General Public License
 along with this program.  If not, see <https://www.gnu.org/licenses/>.

Copyright © 2019 Cloud Linux Software Inc.

This software is also available under ImunifyAV commercial license,
see <https://www.imunify360.com/legal/eula>
*/
import { AgentResponseData, RequestParams } from './misc';


export class LicenseAPI {
    static register = (licenseKey) => new RequestParams(['register'], {regkey: licenseKey});
    static unregister = () => new RequestParams<AgentResponseData<null>>(['unregister']);
    static version = () => new RequestParams(['version']);
    static rstatus = () => new RequestParams(['rstatus']);
}


/**
 * Defines available features and upgrade options.
 */
export enum Product {
    imunifyAV = 0,
    imunifyAVPlus = 1,
    imunify360 = 2,
}

/**
 * Defines available features and upgrade options.
 */
export enum LicenseType {
    imunifyAV = 'imunifyAV',
    imunifyAVPlus = 'imunifyAVPlus',
    imunify360Trial = 'imunify360Trial',
    imunify360 = 'imunify360',
}

/**
 * Used to distinguish which plugin and RPM/DEB package is currently installed.
 */
export enum Package {
    imunifyAV = 'AV',
    imunify360 = '360',
}
declare const PACKAGE: Package;
const productIdToReadableName = {  // ugh
    [Product.imunifyAV]: 'ImunifyAV',
    [Product.imunifyAVPlus]: 'ImunifyAV+',
    [Product.imunify360]: 'Imunify360',
};

// FIXME: this should be reviewed during next bug fixing
type ValidLicenseInBackend = {
    id: string;
    license_type: LicenseType | null;
    message: string | null;
    redirect_url: string | null;
    status: boolean;
    user_count: null | number;
    user_limit: number;
    ip_license?: boolean;
    expiration?: number | null;
} | {
    // https://sentry.cloudlinux.com/sentry/imunify360/issues/8223306
    // it sends random data...
    license_type?: LicenseType | null,
    status: boolean,
};

export type LicenseInBackend = ValidLicenseInBackend | {
    status: false,
    redirect_url: null,
};

export class License {
    /* Effective product (e.g. what logo to use). Depends on PACKAGE and license_type. */
    product: Product = Product.imunifyAV;
    productName: string;

    /**
     * The type of used license
     */
    license_type: LicenseType | undefined;  // tslint:disable-line:variable-name

    user_limit: number;  // tslint:disable-line:variable-name
    user_count: number | null;  // tslint:disable-line:variable-name

    /**
     * Message from CLN
     */
    message: string = '';

    /**
     * In case of upgrade do not propose to enter license key
     */
    ip_license?: boolean = false;  // tslint:disable-line:variable-name

    /**
     * Where to redirect when user clicks "Upgrade"
     */
    upgrade_url: string;  // tslint:disable-line:variable-name

    /**
     * Where to redirect if user has troubles with license in Imunify360
     * FIXME: naming
     */
    redirect_url: string;  // tslint:disable-line:variable-name

    /**
     * Whether the license is appropriate for the installed package or not
     */
    status: boolean;
    id: string;
    public constructor(data: any,
                       /*Can not set to LicenseInBackend, because we do not know its type*/) {
        Object.assign(this, data);
        this.redirect_url = data.redirect_url || '';
        this.product = this.getProduct();
        this.productName = productIdToReadableName[this.product];
    }

    public get cleanupAllowed() {
        return this.product === Product.imunify360
            || (this.product === Product.imunifyAVPlus && this.status);
    }

    public get upgradeAvailable() {
        // TODO: change to < Product.imunify360 after upgrade to Imunify360 is implemented
        return this.product < Product.imunifyAVPlus;
    }

    /**
     * Applicable only to Package.imunify360 - we block the whole UI there
     * if the license is not sufficient.
     * While in ImunifyAV we are just forbidding cleanup actions.
     */
    public isFailed(): boolean {
        return PACKAGE === Package.imunify360 && !this.status;
    }

    public hasMessage(): boolean {
        return !!this.message;
    }

    public isLimitExceeded() {
        return this.user_count != null && this.user_count > this.user_limit;
    }

    private getProduct() {
        switch (PACKAGE) {
            case Package.imunify360:
                return Product.imunify360;
            case Package.imunifyAV:
                return !this.license_type || this.license_type === LicenseType.imunifyAV
                    ? Product.imunifyAV
                    : Product.imunifyAVPlus;
            default:
                throw new Error(`Unknown package ${PACKAGE}`);
        }
    }
}

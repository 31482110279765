/*

This program is free software: you can redistribute it and/or modify it under 
the terms of the GNU General Public License as published by 
the Free Software Foundation, either version 3 of the License, 
or (at your option) any later version.


This program is distributed in the hope that it will be useful, 
but WITHOUT ANY WARRANTY; without even the implied warranty of 
MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  
See the GNU General Public License for more details.


You should have received a copy of the GNU General Public License
 along with this program.  If not, see <https://www.gnu.org/licenses/>.

Copyright © 2019 Cloud Linux Software Inc.

This software is also available under ImunifyAV commercial license,
see <https://www.imunify360.com/legal/eula>
*/
import { Inject, Injectable } from '@angular/core';
import * as parent from '@ngx-translate/core';
import { LANGUAGE_KEY, Panel } from 'app/utils/panel';
import { DOCUMENT } from '@angular/common';
import { noop, Observable } from 'rxjs';
import * as moment from 'moment';
import { Overlay, OverlayConfig, OverlayRef } from '@angular/cdk/overlay';
import { Directionality } from '@angular/cdk/bidi';
import { map } from 'rxjs/operators';
import * as Raven from 'raven-js';

const translateGetParsedResult = parent.TranslateService.prototype.getParsedResult;

const DEFAULT_LANGUAGE = 'en';

export const PANEL_DEFAULT_LANGUAGE = 'panelLang';

export class UntranslatableString {
    static for(value: string) {
        return new this(value || '') as any as string;
    }

    get length() {
        return this.value.length;
    }

    toString() {
        return this.value;
    }

    private constructor(public value: string) {
    }
}

const ignoreSentryPatterns: RegExp[] = [
    /^One or more domains provided do not exist.*/,
    /^malware\.onDemand\.status.*/,
    /Path .* should be absolute/,
    /^IP [0-9a-fA-F\.\/\:]* is already in [A-Z]* list/,
    /^Country [A-F]* is already in [A-Z]* list/,
    /^[0-9]*\/[0-9]* ip\(s\) were successfully deleted/,
    /has host bits set$/,
    /^Noop: /,
    /^Authentication failed/,
    /^User name not found/,
    /^Feature '[a-zA-Z]*' is disabled for user/,
    /^\[Errno 104\] Connection reset by peer$/,
    /^Kernel is unsupported/,
    /^Can't delete KernelCare, because it's not installed$/,
    /^Request timeout$/,
    /^Empty response from socket$/,
    /^Request processing error$/,
    /^Licenses limit exceeded$/,
    /^On-demand scan is already running$/,
    /Too many open files/,
    /Connection refused$/,
    /^400 Client Error: BAD REQUEST for url/,
];
parent.TranslateService.prototype.getParsedResult = function(translations,
                                                             key, interpolateParams) {
    if (key instanceof UntranslatableString) {
        return key.value;
    }
    const result = translateGetParsedResult.call(this, translations, key, interpolateParams);
    if (ENV !== 'test' && result === key
        && !ignoreSentryPatterns.some(pattern => pattern.test(key))) {
        const error = {
            reason: 'Can not find translation',
            key,
            interpolateParams,
        };
        console.error(error);
        Raven.captureException(new Error(JSON.stringify(error)));
    }
    return result;
};

export type TranslateParams = {
    [key: string]: any,
};

@Injectable()
export class TranslateService {
    readonly langs = [{
        code: 'en',
        moment: 'en',
        name: 'English',
    }, {
        code: 'es',
        moment: 'es',
        name: 'Español',
    }, {
        code: 'ru',
        moment: 'ru',
        name: 'Русский',
    }, {
        code: 'ja',
        moment: 'ja',
        name: '日本語',
    }, {
        code: 'de',
        moment: 'de',
        name: 'Deutsch',
    }, {
        code: 'fr',
        moment: 'fr',
        name: 'Français',
    }, {
        code: 'it',
        moment: 'it',
        name: 'Italiano',
    }, {
        code: 'nl',
        moment: 'nl',
        name: 'Nederlands',
    }, {
        code: 'pt-br',
        moment: 'pt-br',
        name: 'Português',
    }, {
        code: 'tr',
        moment: 'tr',
        name: 'Türkçe',
    }, {
        code: 'zh-cn',
        moment: 'zh-cn',
        name: '简体中文',
    }];

    readonly rtlLangs: string[] = [
        // 'es',
    ];

    public currentLang: string;
    public logicalLang: string;

    panelLang: string;
    html: HTMLHtmlElement | null;
    dirContainer: HTMLElement | null;

    getDir(lang: string) {
        return this.rtlLangs.includes(lang) ? 'rtl' : 'ltr';
    }

    constructor(
        public translate: parent.TranslateService,
        private panel: Panel,
        @Inject(DOCUMENT) private document: Document,
    ) {
        this.html = this.document.querySelector('html');
        this.dirContainer = this.html!.getElementsByClassName('i360-app')[0] as HTMLElement;
        this.panelLang = this.panel.getLang(this.document);

        this.initLanguages();

        const create = Overlay.prototype.create;
        let dir;
        Object.defineProperty(Directionality.prototype, 'value', {
            get: () => dir,
            set: noop,
        });
        Object.defineProperty(Directionality.prototype, 'change', {
            get: () => dirChanged,
            set: noop,
        });
        const dirChanged = translate.onLangChange.pipe(
            map(({lang}) => this.getDir(lang)),
        );
        dirChanged.subscribe(d => dir = d);
        Overlay.prototype.create = function(config?: OverlayConfig) {
            const ref: OverlayRef = create.call(this, config);
            Object.defineProperty((ref as any)._config, 'direction', {
                get: () => dir,
                set: noop,
            });
            return ref;
        };
        this.translate.onLangChange.subscribe(({lang}) => this.setMomentLocale(lang));
        // if `<defaultLang>.i18n` file is finished loading after `<currentLang>.i18n` -
        // moment global locale wil get reset during import (due to `getSetGlobalLocale` call in
        // `defineLocale`)
        // We need to force it back after the import is done:
        this.translate.onDefaultLangChange.subscribe(() => this.setMomentLocale(this.currentLang));
    }

    use(lang: string) {
        localStorage.setItem(LANGUAGE_KEY, lang);
        this.logicalLang = lang;
        if (lang === PANEL_DEFAULT_LANGUAGE || !lang) {
            lang = this.panelLang;
        }
        this.currentLang = this.getAppropriateLang(
            lang,
            this.langs.map(lang => lang.code),
            DEFAULT_LANGUAGE);
        this.translate.use(this.currentLang);
        this.ensureRtl(this.currentLang);
    }

    setMomentLocale(lang: string) {
        let item = this.langs.find(i => i.code === lang);
        lang = item ? item.moment : lang;
        moment.locale(lang);
    }

    t(key: string | string[], interpolateParams?: Object): Promise<string> {
        return this.translate.get(key, interpolateParams).toPromise();
    }

    stream(key: string | string[], interpolateParams?: Object): Observable<string | any> {
        return this.translate.stream(key, interpolateParams);
    }

    getAppropriateLang(lang: string, langs: string[], defaultLang: string): string {
        if (langs.includes(lang)) {
            return lang;
        }
        const [standardLang, dialect] = lang.split('-');
        if (dialect && langs.includes(standardLang)) {
            return standardLang;
        }
        return defaultLang;
    }

    initLanguages() {
        const currentleng = localStorage.getItem(LANGUAGE_KEY) || '';
        this.translate.addLangs(this.langs.map(lang => lang.code));
        this.translate.setDefaultLang(DEFAULT_LANGUAGE);
        this.use(currentleng);
    }

    private ensureRtl(lang: string) {
        if (this.html) {
            const dir = this.getDir(lang);
            this.html.classList.remove('rtl', 'ltr');
            this.html.classList.add(dir);
            if (this.dirContainer) {
                this.dirContainer.dir = dir;
            }
        }
    }
}

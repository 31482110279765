import * as tslib_1 from "tslib";
/*

This program is free software: you can redistribute it and/or modify it under
the terms of the GNU General Public License as published by
the Free Software Foundation, either version 3 of the License,
or (at your option) any later version.


This program is distributed in the hope that it will be useful,
but WITHOUT ANY WARRANTY; without even the implied warranty of
MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.
See the GNU General Public License for more details.


You should have received a copy of the GNU General Public License
 along with this program.  If not, see <https://www.gnu.org/licenses/>.

Copyright © 2019 Cloud Linux Software Inc.

This software is also available under ImunifyAV commercial license,
see <https://www.imunify360.com/legal/eula>
*/
import { AbstractService } from 'app/services/abstract-service';
import { InstallationAPI } from '@imunify360-api/installation';
import { responses } from '@imunify360-api/stubs/installation';
import { Mock } from 'app/services/mock';
import * as i0 from "@angular/core";
import * as i1 from "../../utils/xhr";
var InstallationBackendService = /** @class */ (function (_super) {
    tslib_1.__extends(InstallationBackendService, _super);
    function InstallationBackendService() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.status = _this.rxServiceRequest(InstallationAPI.status);
        _this.start = _this.serviceRequest(InstallationAPI.start);
        return _this;
    }
    InstallationBackendService.prototype.getDefaultMockList = function () {
        return !TEST ? [] : [
            Mock.add(InstallationAPI.status, responses.pieceOfLog),
            Mock.add(InstallationAPI.start, responses.serviceFailedToInstall),
        ];
    };
    InstallationBackendService.ngInjectableDef = i0.defineInjectable({ factory: function InstallationBackendService_Factory() { return new InstallationBackendService(i0.inject(i1.XHR)); }, token: InstallationBackendService, providedIn: "root" });
    return InstallationBackendService;
}(AbstractService));
export { InstallationBackendService };

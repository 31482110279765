/*

This program is free software: you can redistribute it and/or modify it under 
the terms of the GNU General Public License as published by 
the Free Software Foundation, either version 3 of the License, 
or (at your option) any later version.


This program is distributed in the hope that it will be useful, 
but WITHOUT ANY WARRANTY; without even the implied warranty of 
MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  
See the GNU General Public License for more details.


You should have received a copy of the GNU General Public License
 along with this program.  If not, see <https://www.gnu.org/licenses/>.

Copyright © 2019 Cloud Linux Software Inc.

This software is also available under ImunifyAV commercial license,
see <https://www.imunify360.com/legal/eula>
*/
import { EventEmitter, Injectable, Injector } from '@angular/core';
import {
    HttpClient,
    HttpErrorResponse,
    HttpResponse,
} from '@angular/common/http';
import { AgentResponse, ExtendedRequestParams,
    NormalizedAgentResponse, Result } from '@imunify360-api/misc';
import { NotificationsService } from 'app/services/notifications';
import { XhrReporterService } from 'app/services/xhr-reporter';
import { of ,  throwError as _throw ,  timer ,  Observable } from 'rxjs';
import { catchError, map, retryWhen, scan, switchMap } from 'rxjs/operators';
import { UntranslatableString } from 'app/utils/translate/translate.service';


declare let ENV: string;

/**
 * @returns {string} JSON-like string with a lot of escaping when converting circular values,
 * but it is short
 */
function toString(deepness = 0) {
    return JSON.stringify(this, function replacer(key, value) {
        try {
            JSON.stringify(value);
            return value;
        } catch (e) {
            if (deepness >= 3) {
                return 'Circular';
            }
            let res = {};
            Object.keys(value).forEach(key => {
                res[key] = toString.call(value[key], deepness + 1);
            });
            return res;
        }
    });
}
function addToString(error) {
    Object.defineProperty(error, 'toString', {
        value: toString,
        enumerable: false,
    });
}

@Injectable({
    providedIn: 'root',
})
export class XHR {
    request: HttpClient;
    loaded = new EventEmitter();

    constructor(
        private http: HttpClient,
        private injector: Injector,
        private notifications: NotificationsService,
    ) {}

    public _throw(e) {
        // for mock in test
        return _throw(e);
    }

    post<R = any>(
        params: ExtendedRequestParams<R> | FormData,
        mock: () => Observable<R> | null = () => null,
        notifyOnWarning = true,
        notifyOnError = true,
    ): Observable<NormalizedAgentResponse<R>> {
        let startDate: number = 0;
        let post = of(null).pipe(
            switchMap(() => {
                startDate = Date.now();
                // fast attempt to call interceptors when testMode=true
                // can be broken with async interceptors and parallel requests
                // but for now all interceptors are sync, so request should not be called
                // when mock is applied
                const mocked = mock();
                if (mocked) {
                    const prevHandle = this.http['handler'].backend.handle;
                    this.http['handler'].backend.handle = () => {
                        this.http['handler'].backend.handle = prevHandle;
                        return mocked.pipe(
                            switchMap(res => {
                                return of(new HttpResponse({
                                    body: res,
                                }));
                            }),
                        );
                    };
                }
                return this.http.post('', params);
            }),
        );
        if (ENV === 'development') {
            post = post.pipe(retryWhen(error => {
                return error.pipe(
                    scan((acc, res: any | null) => {
                        acc.res = res;
                        acc.acc += 1;
                        return acc;
                    }, {acc: 0, res: null}),
                    switchMap((acc: any) => {
                        if (acc.acc < 3) {
                            return timer(2000);
                        } else {
                            throw acc.res;
                        }
                    }),
                );
            }));
        }

        return post.pipe(
            catchError(async err => {
                this.showResponseErrors(err);
                throw err;
            }),
            map((rawResponse: AgentResponse<R>) => {
                const messages = rawResponse.messages;
                const response: ExtendedAgentResponse<R> = {
                    data: rawResponse.data,
                    result: rawResponse.result,
                    messages: messages
                        ? typeof messages === 'string' ? [messages] : messages
                        : [],
                };

                if (TEST) {
                    XhrReporterService.report(params, response, startDate);
                }
                this.loaded.emit();
                switch (response.result) {
                    case Result.SUCCESS:
                        return response;
                    case Result.WARNING:
                        response.noSentry = true;
                        if (notifyOnWarning) {
                            this.showResponseErrors(response);
                        }
                        throw response;
                    case Result.ERROR:
                        response.noSentry = true;
                        if (notifyOnError) {
                            this.showResponseErrors(response);
                        }
                        throw response;
                    default:
                        // For success cli may not return result key
                        return response;
                }
            }),
            catchError(error => {
                addToString(error);
                return this._throw(error);
            }),
        );
    }

    showResponseErrors(error: AgentResponse | HttpErrorResponse) {
        if ('message' in error) {
            const content = (new DOMParser()).parseFromString(error.error, 'text/html');
            this.notifications.error(UntranslatableString.for(content['body'].innerText.trim()),
                `notifications.${Result.ERROR}`);
        } else if ('messages' in error) {
            let license = {};
            try {
                const LicenseService = require('app/services/license').LicenseService;
                license = this.injector.get(LicenseService).license;
            } catch (e) {
                // license service is not available in login module
            }
            for (let msg of error.messages) {
                this.notifications.error(msg, `notifications.${error.result}`,
                    license);
            }
        }
    }
}

export interface ExtendedAgentResponse<R> extends NormalizedAgentResponse<R> {
    noSentry?: boolean;
}
